import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { toItemPagePath } from '~/app/lib/router2/utils';
import { SongwhipTrack } from '../types';
import { toTimestamp } from '../../utils/date';
import { ItemTypes } from '~/types';

import { MappedTrack } from './types';
import artistMapper from './mapArtist';

const mapTrack = (payload: SongwhipTrack) =>
  removeUndefinedKeys<MappedTrack>({
    type: ItemTypes.TRACK,
    id: payload.id,
    path: payload.path,
    pagePath: toItemPagePath(payload.path),
    name: payload.name,
    image: payload.image || undefined,
    links: payload.links!,
    linksCountries: payload.linksCountries || undefined,
    sourceCountry: payload.sourceCountry!,

    // handle mapping serialized Tracks that don't have artists defined
    // (eg. in edgeWorker responding to on-record-change hook)
    artists: payload.artists?.map(artistMapper),

    createdAtTimestamp: toTimestamp(payload.createdAt),
    refreshedAtTimestamp: toTimestamp(payload.refreshedAt),

    config: payload.config || undefined,
    ownedByAccounts: payload.ownedByAccounts,
    customLinks: payload.customLinks,
  });

export default mapTrack;
