import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';

type ToImageProps = {
  url: string;
  width?: string;
  height?: string;
  title?: string;
};

type SchemaImage = {
  '@type': 'ImageObject';
  '@id': string;
  url: string;
  width?: string;
  height?: string;
  caption?: string;
};

const toImage = ({ url, width, height, title }: ToImageProps) =>
  url
    ? removeUndefinedKeys<SchemaImage>({
        '@type': 'ImageObject',
        '@id': `${url}/#image`,
        url,
        width,
        height,
        caption: title,
      })
    : undefined;

export default toImage;
