import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';

type ToWebPageProps = {
  url: string;
  title: string;
  description?: string;
  breadcrumbId?: string;
  language?: string;
  primaryImageId?: string;
  websiteId?: string;
  date?: Date;
};

const toWebPage = ({
  url,
  title,
  description,
  breadcrumbId,
  language = 'en-US',
  primaryImageId,
  websiteId,
  date,
}: ToWebPageProps) => {
  return removeUndefinedKeys({
    '@type': 'WebPage',
    '@id': `${url}/#webpage`,
    url,
    inLanguage: language,
    name: title,
    description,
    isPartOf: websiteId && {
      '@id': websiteId,
    },
    primaryImageOfPage: primaryImageId && {
      '@id': primaryImageId,
    },
    datePublished: date && new Date(date).toISOString(),
    // TODO: make this unique
    dateModified: date && new Date(date).toISOString(),
    breadcrumb: breadcrumbId && {
      '@id': breadcrumbId,
    },
  });
};

export default toWebPage;
