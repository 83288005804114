import React, { FC } from 'react';

import ErrorText, { ErrorTextProps } from '../ErrorText';
import { AppError } from '~/app/lib/errors/types';
import ApiError from '~/app/lib/errors/ApiError';
import Button from '../Button';
import { useI18n } from '~/app/lib/i18n';
import Box from '../Box';

export interface PageErrorProps extends Pick<ErrorTextProps, 'toText'> {
  error: AppError;
  testId?: string;
  withRefreshButton?: boolean;
  withContactButton?: boolean;
}

const PageError: FC<PageErrorProps> = ({
  error,
  toText,
  testId,
  withRefreshButton,
  withContactButton,
}) => {
  const { t } = useI18n();
  const isNetworkError = error instanceof ApiError && error.status === 0;

  return (
    <Box padding="3.6rem" coverParent centerContent>
      <Box maxWidth="40rem" flexColumn>
        <ErrorText
          testId={testId}
          error={error}
          size="2.2rem"
          lineHeight={1.3}
          centered
          isBold
          toText={toText}
        />
        {(withRefreshButton || isNetworkError) && (
          <Button
            margin="2.5rem auto 0"
            onClick={() => location.reload()}
            text={t('app.actions.refreshApp')}
          />
        )}
        {withContactButton && (
          <Button
            text={t('app.actions.contactSupport')}
            testId="contactSupport"
            margin="2.5rem auto 0"
            href="/contact"
          />
        )}
      </Box>
    </Box>
  );
};

export default PageError;
