import { SchemaTypeBase } from './types';

export interface SchemaItemListItem {
  '@type': 'ListItem';
  position: number;
  item: string | SchemaTypeBase;
}

export interface SchemaItemList extends SchemaTypeBase {
  '@type': 'ItemList' | 'BreadcrumbList';
  numberOfItems: number;
  mainEntityOfPage?: string;
  itemListElement: SchemaItemListItem[];
}

const toItemList = ({
  type = 'ItemList',
  pageUrl,
  items,
  webPageId,
}: {
  type?: 'ItemList' | 'BreadcrumbList';
  pageUrl: string;
  items: string[];
  webPageId?: string;
}): SchemaItemList => ({
  '@type': type,
  '@id': `${pageUrl}#${type}`,
  numberOfItems: items.length,
  mainEntityOfPage: webPageId,

  itemListElement: items.filter(Boolean).map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item,
  })),
});

export default toItemList;
