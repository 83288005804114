import removeUndefinedKeys from '../../utils/removeUndefinedKeys';
import { toItemPagePath } from '../../router2/utils';
import { ItemTypes } from '~/types';

import artistMapper from './mapArtist';
import { SongwhipAlbum } from '../types';
import { toTimestamp } from '../../utils/date';
import { MappedAlbum } from './types';

const mapAlbum = (payload: SongwhipAlbum) =>
  removeUndefinedKeys<MappedAlbum>({
    type: ItemTypes.ALBUM,
    id: payload.id,
    path: payload.path,
    pagePath: toItemPagePath(payload.path),
    name: payload.name,
    image: payload.image || undefined,
    links: payload.links,
    linksCountries: payload.linksCountries || undefined,
    sourceCountry: payload.sourceCountry!,
    releaseDate: payload.releaseDate!,
    upc: payload.upc,

    // handle mapping serialized Tracks that don't have artists defined
    // (eg. in edgeWorker responding to on-record-change hook)
    artists: payload.artists?.map(artistMapper),

    createdAtTimestamp: toTimestamp(payload.createdAt),
    refreshedAtTimestamp: toTimestamp(payload.refreshedAt),

    config: payload.config || undefined,
    isPrerelease: payload.isPrerelease,
    isDraft: payload.isDraft,

    ownedByAccounts: payload.ownedByAccounts,
    customLinks: payload.customLinks,
  });

export default mapAlbum;
