import toImage from './toImage';

export interface OrganizationSchema {
  '@type': 'Organization';
  '@id': string;
  url: string;
  name: string;
  image: any;
  logo: any;
  description: string;
  sameAs: any;
}

const toOrganization = ({
  url,
  name,
  description,
  imageUrl,
  links,
}): OrganizationSchema => {
  const image = toImage({
    url: imageUrl,
    width: '512',
    height: '512',
    title: name,
  });

  return {
    '@type': 'Organization',
    '@id': `${url}#organization`,
    url,
    name,

    image: image && {
      '@id': image['@id'],
    },

    logo: image,
    description,
    sameAs: links,
  };
};

export default toOrganization;
