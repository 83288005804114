import { toPublicEndpoint } from '../../getPublicEndpoint';
import toItemList, { SchemaItemList } from './toItemList';
import toWebPage from './toWebPage';

export type ToBreadcrumbPropsItem = {
  pathPath: string;
  name: string;
  [key: string]: any;
};

export type ToBreadcrumbProps = {
  items: ToBreadcrumbPropsItem[];
  pageUrl: string;
  websiteId?: string;
};

export interface SchemaBreadcrumbList extends SchemaItemList {
  '@type': 'BreadcrumbList';
  pageUrl: string;
}

const toBreadcrumb = ({ items, pageUrl }: ToBreadcrumbProps) => {
  return toItemList({
    type: 'BreadcrumbList',
    pageUrl,

    items: items.filter(Boolean).map(({ pagePath, name, ...rest }) => ({
      ...(toWebPage({
        url: toPublicEndpoint(pagePath),
        title: name,
      }) as any),

      // allow callee to mix in custom props
      ...rest,
    })),
  }) as SchemaBreadcrumbList;
};

export default toBreadcrumb;
