export type SchemaSearchAction = {
  '@type': 'SearchAction';
  target: string;
  'query-input': string;
};

const toSearchAction = ({ url }: { url: string }): SchemaSearchAction => ({
  '@type': 'SearchAction',
  target: `${url}{search_term_string}`,
  'query-input': 'required name=search_term_string',
});

export default toSearchAction;
