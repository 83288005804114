import { SchemaSearchAction } from './toSearchAction';
import { SchemaIdReference } from './types';

export type WebsiteSchema = {
  '@type': 'WebSite';
  '@id': string;
  url: string;
  name: string;
  publisher?: SchemaIdReference;
  potentialAction: SchemaSearchAction;
};

type ToWebsiteParams = {
  url: string;
  name: string;
  organizationId: string;
  action: SchemaSearchAction;
};

const toWebsite = ({
  url,
  name,
  organizationId,
  action,
}: ToWebsiteParams): WebsiteSchema => ({
  '@type': 'WebSite',
  '@id': `${url}#website`,
  url,
  name,
  potentialAction: action,
  publisher: {
    '@id': organizationId,
  },
});

export default toWebsite;
