export const toTimestamp = (data: string | undefined | null) => {
  if (!data) return;

  const timestamp = new Date(data).getTime();
  return isNaN(timestamp) ? undefined : timestamp;
};

export const resolveAgeFromTimestamp = (
  timestamp: number
): number | undefined => {
  if (isNaN(timestamp)) return;

  const timestampDiff = new Date(Date.now() - timestamp);
  const age = timestampDiff.getUTCFullYear() - new Date(0).getUTCFullYear();

  return age > 0 ? age : 0;
};

export const getDaysInMonth = (year: number, month: number) =>
  new Date(year, month, 0).getDate();

export const getCurrentYear = () => new Date().getFullYear();

export const getDateParts = (
  dateString: string,
  format: string
): { year: number; month: number; day: number } | undefined => {
  const parts = dateString.split(/[^0-9]+/);
  const formatParts = format.match(/(YYYY|MM|DD)/g);

  let year: number | undefined;
  let month: number | undefined;
  let day: number | undefined;

  if (parts.length !== formatParts?.length) return;

  for (let i = 0; i < parts.length; i++) {
    const part = parseInt(parts[i], 10);
    const formatPart = formatParts[i];

    if (isNaN(part)) return;

    switch (formatPart) {
      case 'YYYY':
        year = part;

        break;
      case 'MM':
        month = part;

        break;
      case 'DD':
        day = part;

        break;
      default:
        return;
    }
  }

  if (!year || !month || !day) return;

  return { year, month, day };
};

export const getDateFromString = (
  dateString: string,
  format: string
): Date | undefined => {
  const parts = getDateParts(dateString, format);

  if (!parts) return;

  const { year, month, day } = parts;
  const date = new Date(year, month - 1, day);

  if (isNaN(date.getTime())) return;

  return date;
};

export const isDateStringValid = (
  dateString: string,
  format: string,

  // Timestamp range
  range?: [number, number]
): boolean => {
  const dateParts = getDateParts(dateString, format);
  const [minTimestamp, maxTimestamp] = range ?? [];

  if (!dateParts) return false;

  const { year, month, day } = dateParts;
  const date = new Date(year, month - 1, day);
  const dateTimestamp = date.getTime();

  if (isNaN(dateTimestamp)) return false;

  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1;
  const dateDay = date.getDate();

  const isInRange = range
    ? !!minTimestamp &&
      !!maxTimestamp &&
      dateTimestamp >= minTimestamp &&
      dateTimestamp < maxTimestamp
    : true;

  return (
    dateYear === year &&
    dateMonth === month &&
    dateDay === day &&
    year >= 1000 &&
    year <= 2999 &&
    month >= 1 &&
    month <= 12 &&
    day >= 1 &&
    day <= getDaysInMonth(year, month) &&
    isInRange
  );
};
